<template>
  <div class='container2'>
    <nav class="recruit_nav">
      <ul>
        <li v-for="(nav,index) in navs" :key="index" :class="{'active': nav.active}" >
          <router-link v-if="nav.isChild" :to="nav.name">{{ nav.title }}</router-link>
        </li>
      </ul>
    </nav>
    <div class="recruit_main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'work-index',
  data () {
    return {
      navs: [
        {
          title: "职位",
          name: 'project-manage-job',
          path: '/project-manage-job',
          isChild: 1,
          num: 0,
          active: false
        },
        {
          title: "众包",
          name: 'project-manage-zhongbao',
          path: '/project-manage-zhongbao',
          isChild: 1,
          num: 0,
          active: false
        },
       

      ]
    }
  },
  created () {
    console.log(this.$route.name);

  },
  methods: {
    goTo (name) {
      let routeData = this.$router.resolve({ name: name, });
      window.open(routeData.href, '_blank');

    },


  }
}
</script>

<style lang="scss" scoped>

.container2{
  background: #ffffff;
  padding:20px
}
.recruit_nav {
  // height: 72px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 19px;
  padding:10px 0px;

  ul {
    display: flex;

    li {
      position: relative;
      a {
        padding: 0px 24px;
        display: block;
        font-size: 16px;
        &.router-link-active {
          //background-color: #4c71db;
          border-bottom: 1px solid #4c71db;
          color:#4c71db ;
          font-weight: bold;
          //color: #fff;
        }
      }
      aside {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: #ff4e4e;
        border-radius: 10px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }
    }

   
  }
}
.recruit_main {
  border-radius: 6px;
  
}
</style>